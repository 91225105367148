@import 'src/index.scss';

@keyframes slideInRight {
    0% {
        right: -50%;
        width: 0;
    }
    100% {
        right: 50%;
    }
}

.notifier-container {
    width: 40rem;
    padding: 1.5rem 2rem !important;
    position: fixed;
    top: 3rem;
    //left: 0;
    z-index: 15 !important;
    border-radius: 0.8rem;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    padding: 1.5rem !important;
    transition: all 1s;


    @media (max-width: 345px) {
        width: 35rem
    }

    @media (max-width: 295px) {
        width: 33rem
    }
}

.notifier-text {
    font-size: 1.5rem;
    padding-left: 3rem;
    margin: 0 !important;
    //font-weight: 500;
}
.message-icon {
    font-size: 3.5rem;
}