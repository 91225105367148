@import 'src/index.scss';

.profile-container {
    padding: 3rem 8rem 2rem 8rem !important;

    @media (max-width: 770px) {
        padding: 3rem 2rem 2rem 4rem !important;
    }
}

.profile-content-wrapper {
    @media (max-width: 410px) {
        flex-wrap: wrap !important;
    }
}

.profile-pics-container {
    @media (max-width: 410px) {
        width: 100% !important;
    }
}

.profile-details-container {
    width: -webkit-calc(100% - 22.4rem) !important;
    width: -moz-calc(100% - 22.4rem) !important;
    width: calc(100% - 22.4rem) !important;
    min-width: 25rem !important;

    @media (max-width: 410px) {
        width: 100% !important;
    }
}

.no-submenu-container {
    padding-left: 8rem !important;
    padding-right: 8rem !important;

    @media (max-width: 770px) {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
}

.checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer
}

.checkbox {
    width: 20px;
    height: 20px;
    accent-color: #0D968F;
    margin-right: 10px;
    cursor: pointer
}
