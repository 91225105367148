@import 'src/index.scss';

.audit-details-container {
    padding: 2rem 0 !important;
    @media (max-width: 770px) {
        padding: 2rem 3rem !important;
    }
}

.audit-details-content-container {
    @media (max-width: 1120px) {
        flex-wrap: wrap !important;

        > div {
            width: 100% !important;
        }
    }
}

// .audit-details-item-container {
//     @media (max-width: 1200px) {
//         flex-wrap: wrap !important;
//     }
//     // @media (max-width: 1120px) {
//     //     //flex-wrap: nowrap !important;
//     // }
//     @media (max-width: 360px) {
//         flex-wrap: wrap !important;
//     }
// }

.audit-details-item {
    min-width: 45% !important;
    // @media (max-width: 1200px) {
    //     width: 100% !important;
    // }

    // @media (max-width: 1120px) {
    //     width: 45% !important;
    // }

    // @media (max-width: 360px) {
    //     width: 100% !important;
    // }
}