@import 'src/index.scss';


.phone-main-wrapper {
    position: relative;
    margin: 0 auto;
    width: 21rem;
    height: 42rem;
}
.content-container {
    width: 100%; //21rem
    height: 100%; //42rem
    border-radius: 3.5rem;
    border: 0.8rem solid #000000;
    position: relative;
    // padding: 0.2rem 1rem 1rem 1rem;
    //margin: 0 auto;
    overflow-y: hidden;
    overflow-x: auto;
}
.top-notch {
    position: absolute;
    width: 8.5rem;
    height: 2rem;
    background-color: #000000;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
}
.notification-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.notif-icon-wrap {
    display: flex;
    width: auto;
    justify-content: space-between;
    padding: 0.2rem 1rem;
}

.notif-icon {
    font-size: 8px;
    margin-left: 3px;

    &:last-child {
        margin-right: 5px;
    }
}

.notif-time {
    font-size: 8px;
    margin-left: 10px;
    font-weight: bold;
    padding: 0.2rem 1rem;
}

.silent-toggle {
    width: 8px;
    height: 25px;
    border-radius: 2px;
    background-color: #000000;
    position: absolute;
    left: -2.2px;
    top: 80px
}

.volume-up {
    width: 8px;
    height: 40px;
    border-radius: 2px;
    background-color: #000000;
    position: absolute;
    left: -2.2px;
    top: 130px
}

.volume-down {
    width: 8px;
    height: 40px;
    border-radius: 2px;
    background-color: #000000;
    position: absolute;
    left: -2.2px;
    top: 180px
}

.power-button {
    width: 8px;
    height: 60px;
    border-radius: 2px;
    background-color: #000000;
    position: absolute;
    right: -2.2px;
    top: 150px
}

.phone-content-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}