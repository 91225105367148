@import 'src/index.scss';

.first-design-logo, .second-design-logo {
    transform: scale(0.4);

    @media (max-width: 900px) {
        transform: scale(0.35);
    }
}
.first-design-circle {
    transform: scale(0.45);
    margin-top: -1rem;

    @media (max-width: 900px) {
        transform: scale(0.35);
        margin-top: -4rem;
    }

    @media (max-width: 600px) {
        transform: scale(0.33);
        margin-top: -5rem;
    }
}

.first-design-circle ~ .title-first-design {

    @media (max-width: 900px) {
        margin-top: -4.5rem !important;
    }
}

.second-design-logo {
    margin-left: -3rem;
}

.scale-top-semi-circle {
   transform: scale(0.6);
   margin-left: -10rem;
   margin-top: -5rem;
}

.second-design-img-wrap {

    @media (max-width: 900px) {
        margin-top: -13rem !important;
    }
    @media (max-width: 600px) {
        margin-top: -14rem !important;
    }
}

.third-design-img-wrap {

    @media (max-width: 900px) {
        margin-top: -11rem !important;
    }
    @media (max-width: 600px) {
        margin-top: -12rem !important;
    }
}