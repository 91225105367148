@import 'src/index.scss';


.login-about-section {
    @media (max-width: 750px) {
        display: none !important;
    }
}
.login-form-section {
    @media (max-width: 750px) {
        width: 100% !important
    }
}
.login-form-container {
    @media (max-width: 1350px) {
        width: 37rem !important
    }
    @media (max-width: 750px) {
        width: 45rem !important
    }
    @media (max-width: 430px) {
        width: 40rem !important
    }
    @media (max-width: 370px) {
        width: 95% !important
    }
}

.login-form-logo {
    display: none;
    margin: 0 auto 4rem auto;

    @media (max-width: 750px) {
        display: block;
    }
}

.login-title, .login-instruction {
    @media (max-width: 750px) {
        display: block;
        text-align: center !important;
    }
}

.login-title {
    @media (max-width: 310px) {
        font-size: 2.5rem !important;
    }
}

.login-icode, .login-email, .login-password {
    width: 100%;
    height: 44px;
    padding: 0 1rem;
    border: 1px solid #D0D5DD;
    color: #667085;
    border-radius: 8px 8px 0px 0px;
    outline: none;
}
.login-email {
    border: 1px solid #D0D5DD;
    border-top: none;
    border-bottom: none;
    border-radius: 0;
}
.login-password {
    border: 1px solid #D0D5DD;
    //border-top: none;
    border-radius: 0px 0px 8px 8px;
}