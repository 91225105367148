@import 'src/index.scss';

.apk-generation-container {
    @media (max-width: 450px) {
        flex-wrap: wrap !important;
    }
}
.apk-type-block {
    @media (max-width: 1050px) {
        width: 100% !important;
    }
}