@import 'src/index.scss';

.designs-container {
    @media (max-width: 1470px) {
        flex-wrap: wrap !important;
    }

    @media (max-width: 1433px) {
        gap: 3rem !important;
    }
}

.design-phone-wrapper {
    @media (max-width: 1500px) {
        width: 29rem !important;
        min-height: 52rem !important;
    }
    @media (max-width: 1433px) {
        width: 48% !important;
    }
    @media (max-width: 1233px) {
        width: 100% !important;
    }
    @media (max-width: 770px) {
        width: 48% !important;
    }
    @media (max-width: 688px) {
        width: 100% !important;
    }

}

.laptop-design-img {
    @media (max-width: 1360px) {
        transform: scale(0.8);
        margin-top: -4rem;
    }
    @media (max-width: 1160px) {
        transform: scaleX(0.6) scaleY(0.7);
        margin-top: -6.5rem;
    }
    @media (max-width: 990px) {
        transform: scaleX(0.5) scaleY(0.6);
        margin-top: -9rem;
    }
    @media (max-width: 770px) {
        transform: scale(.8);
        margin-top: -4rem;
    }
    @media (max-width: 620px) {
        transform: scaleX(.6) scaleY(.7);
        margin-top: -7rem;
    }
    @media (max-width: 470px) {
        transform: scaleX(.5) scaleY(.6);
        margin-top: -9rem;
    }
    @media (max-width: 405px) {
        transform: scaleX(.4) scaleY(.5);
        margin-top: -12rem;
    }
    @media (max-width: 333px) {
        transform: scaleX(.3) scaleY(.4);
        margin-top: -15rem;
    }
}

.laptop-design-wrapper {
    @media (max-width: 1230px) {
        height: 46rem !important;
    }
    @media (max-width: 1060px) {
        height: 41rem !important;
    }
    @media (max-width: 900px) {
        height: 47rem !important;
    }
    @media (max-width: 843px) {
        height: 43rem !important;
    }
    @media (max-width: 770px) {
        height: 52rem !important;
    }
    @media (max-width: 620px) {
        height: 47rem !important;
    }
    @media (max-width: 600px) {
        height: 50rem !important;
    }
    @media (max-width: 470px) {
        height: 45rem !important;
    }
    @media (max-width: 405px) {
        height: 39rem !important;
    }
    @media (max-width: 333px) {
        height: 33rem !important;
    }
}