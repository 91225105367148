@import 'src/index.scss';

.mbanking-main-container {
    @media (max-width: 1090px) {
        flex-direction: column !important;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.mbanking-phone-hand {
    @media(max-width: 1090px) {
        //margin-top: 5rem !important;
        align-self: flex-end !important;
    }

    @media (max-width: 375px) {
        width: 100% !important;
    }
}

.feed-link-input {
    width: -webkit-calc(100% - 11rem) !important;
    width: -moz-calc(100% - 11rem) !important;
    width: calc(100% - 11rem) !important;
}