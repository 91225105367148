@import 'src/index.scss';

.upgrade-doc-container {
    > div {
        display: none !important;
    }

    &:hover > div {
        display: block !important;
    }
}

.doc-preview-container {
    margin-left: auto !important;
    margin-right: auto !important;
}