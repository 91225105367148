@import 'src/index.scss';

.ads-title-wrapper {
    @media (max-width: 1320px) {
        flex-direction: column !important;
        align-items: flex-start !important;
        
        button {
            align-self: flex-end;
            margin-top: 2rem !important;
        }
    }
}

.ads-img {
    min-height: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    height: 100%;
}

.new-ads-container {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    background-color: #ffffff !important;
    transform: translate(-50%, -50%) !important;
    padding: 2rem !important;
    width: 45rem !important;
    min-height: 24rem !important;
    z-index: 10 !important;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03) !important;
    border-radius: 0.8rem !important;
    max-height: 95% !important;
    overflow: auto !important;

    @media (max-width: 385px) {
        width: 93% !important;
    }
}

.ibanking-main-container {
    @media (max-width: 1245px) {
        width: 100% !important;
        margin-right: 0 !important;
    }
}