@import 'src/index.scss';

.dashboard-container {
    width: 100%;
    height: 100%;
    padding-top: 8rem
}

.dashboard-modules {
    width: 100%;
    height: 100%;
    padding: 7rem 8rem 2rem 34rem;

    @media (max-width: 770px) {
        padding: 2rem 0;
        background-color: #ffffff;
    }
}

.dashboard-modules-profile {
    width: 100%;
    height: 100%;
}