@import 'src/index.scss';


.institution-config-item {
    @media (max-width: 600px) {
        flex-wrap: wrap !important;

        div {
            width: 100% !important;
        }
    }
}