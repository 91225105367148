@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$color-white: #ffffff;
$theme-color: #344054;
$theme-background: #F9FAFB;
$theme-deep-color: #101828;

@font-face {
  font-family: 'Gilmer';
  src: local('Gilmer'), url(./fonts/Gilmer/Gilmer-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'Gilmer-Semi-Bold';
  src: local('Gilmer-Semi-Bold'), url(./fonts/Gilmer/Gilmer-Medium.otf) format('truetype');
}

@font-face {
  font-family: 'Gilmer-Bold';
  src: local('Gilmer-Bold'), url(./fonts/Gilmer/Gilmer-Bold.otf) format('truetype');
}

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    } //1800px
  }
}

@mixin zero-space {
  margin: 0;
  padding: 0;
}
@mixin side-space {
  margin: 0 1.4rem;
}
@mixin flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@mixin flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@mixin flex-row-centered {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center
}


@keyframes pulsation {
  from {
    transform: scale(.9)
  }
  to {
    transform: scale(1.3)
  }
}


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html, body {
  font-size: 62.5%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  @include respond(tab-port) {
    font-size: 53%;
  }

  @include respond(phone) {
    font-size: 50%; // 1rem = 8px, 8/16 * 100 = 50%
  }
}
  
input,
textarea,
button,
select,
a,
label,
span,
div,
p{
    -webkit-tap-highlight-color: rgba(0,0,0,0)
}


body {
  margin: 0;
  font-family: Gilmer, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 1.5rem;
  color: $theme-color;
  background-color: $theme-background;

  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none
  }
}

code {
  // font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  //   monospace;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-with-icon {
  border: 1px solid #D0D5DD;
  border-radius: .8rem;
  background-color: transparent;
  //padding: 0 1.5rem;
  color:  #344054;
  height: 4.4rem;
  width: 100%;
  position: relative;
  
  input, select, p {
    height: 4.4rem;
    border: none;
    color: #344054;
    background: transparent;
    width: 100%;
    padding: 0 5rem 0 1.5rem; // new
    outline: none;
    font-size: 1.6rem;

    @media (max-width: 550px) {
      padding-right: 2rem;
    }
  }

  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  select {
      -webkit-appearance: none;
      appearance: none;
      position: absolute;
      top: 0;
      left: 0;
      //z-index: 2;

      option {
          color: rgba(#000, .8)
      }
  }

  .amount-input {
    padding-left: 3rem;
  }

  .input-with-icon-tag, span, .amount-symbol, .select-explainer {
      position: absolute !important;
      top: 50% !important;
      right: 2rem !important;
      transform: translateY(-50%) !important;
      font-size: 2rem;
  }

  .select-explainer {
    right: 5rem !important;
    font-size: 1.7rem;
  }

  span {
      font-size: 1.3rem;
      font-weight: 400
  }

  .amount-symbol {
      width: 2.5rem;
      left: 1.5rem;
      font-size: 1.7rem;
  }
}

.full-column-flex {
  @media (max-width: 420px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.full-width-flex {
  @media (max-width: 420px) {
    width: 100% !important;
    margin-right: 0 !important;
  }
}

.full-column-flex-design {
  @media (max-width: 1240px) {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  @media (max-width: 1090px) {
    flex-direction: row !important;
  }
  @media (max-width: 390px) {
    flex-direction: column!important;
  }
}

.full-width-flex-design {
  @media (max-width: 1240px) {
    width: 100% !important;
    margin-right: 0 !important;
  }
  @media (max-width: 1090px) {
    width: 48.5% !important;

    &:first-child {
      margin-right: 3% !important;
    }
  }
  @media (max-width: 390px) {
    width: 100% !important;
    margin-right: 0 !important;
  }
}

.tooltip-style {
  background-color: #344054 !important;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.5) !important;
  border-radius: 4px;
  font-size: 1.2rem !important;
  font-weight: 400 !important;
}
.my-tooltip {
  max-width: 25rem !important; //30
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #344054 !important;
  border-radius: 1rem !important;
}
.my-tooltip .multi-line {
  text-align: left !important;
}
.hide-tooltip {
  @media (max-width: 550px) {
      display: none !important;
  }
}

.icon-component {
  @media (max-width: 900px) {
      transform: scale(0.9);
  }
  @media (max-width: 600px) {
      transform: scale(0.85);
  }
}

.display-hidden {
  position: absolute;
  font-size: 0.5rem;
  width: 4rem;
  height: 0;
  opacity: 0;
}

.display-hidden:focus ~ .active-otp-box {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;
}

.download-apk-btn {
  border-radius: 100%;
  padding: 8px;
  background-color: transparent;
  transition: all .2s ease-in-out;
  cursor: pointer;
}
.download-apk-btn-disabled {
  border-radius: 100%;
  padding: 8px;
  background-color: transparent;
  opacity: 50%;
  cursor: not-allowed;
}
.download-apk-btn:hover {
  background-color: #f1f1f1;
}

.checkbox-container{
  flex-direction: row;
  position: relative;
  align-items: center;
}

.checkbox-icon{
  align-items: center;
  position: absolute;
  right: 2rem;
  top:1.5rem;
}