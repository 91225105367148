@import 'src/index.scss';

.header-fix-container {
    @media (max-width: 770px) {
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06) !important;
    }
}
.header-wrapper {
    @media (max-width: 990px) {
        padding: 0 5rem !important
    }
    @media (max-width: 770px) {
        padding: 0 3rem !important
    }
}

.header-label {
    @media (max-width: 650px) {
        display: none !important;
    }
}

.header-logo {
    @media (max-width: 900px) {
        transform: scale(0.9);
    }
    @media (max-width: 770px) {
        transform: scale(0.75);
        margin-left: 3rem;
    }
    @media (max-width: 600px) {
        transform: scale(0.6);
        margin-left: 1rem;
    }

    @media (max-width: 400px) {
        display: none !important;
    }
}

.logout-btn-wrapper {
    @media (max-width: 770px) {
        right: 1rem !important
    }
}

.logout-container {
    transition: all .3s;
    border-bottom: 1px solid #EAECF0 !important;

    &:hover {
        background-color: rgba(#0D968F, .08) !important; 

        div {
            span {
                color: #0D968F !important
            }
        }
    }
}

.menu-type-toggle {

    &:hover {
        background-color: #E7F6F5;

        span {
            color: #0D968F !important;
        }
    }
}

.admin-menu {
    @media (max-width: 840px) { //770px
        margin-left: -1rem !important;
        display: none !important;
    }

    // @media (max-width: 720px) {
    //     display: none !important;
    // }
}

.admin-menu-toggle {
    display: none !important;

    @media (max-width: 840px) { // 770px
        display: flex !important;
    }

    // @media (max-width: 720px) {
    //    display: flex !important;
    // }

    @media (max-width: 400px) {
        margin-left: 5rem !important;
    }
}

.update-billers-btn {
    @media (max-width: 540px) {
        display: none !important;
    }
}