@import 'src/index.scss';

.color-picker-container, .color-palette-container {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    background-color: #ffffff !important;
    transform: translate(-50%, -50%) !important;
    padding: 4rem 2rem 2rem 2rem !important;
    width: 26rem !important;
    min-height: 24rem !important;
    z-index: 10 !important;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03) !important;
    border-radius: 0.8rem !important;

    @media (max-width: 900px) {
        width: 30rem !important;
    }
}

.color-palette-container {
    width: auto !important;
}