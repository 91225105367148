@import 'src/index.scss';

.table-column-title {
    font-weight: 600;
    font-size: 1.3rem;
    color: #667085;
    margin: 0
}

.icon-component {
    @media (max-width: 900px) {
        transform: scale(0.9);
    }
    @media (max-width: 600px) {
        transform: scale(0.85);
    }
}