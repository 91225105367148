@import 'src/index.scss';


.feature-control-box {
    width: 31rem !important;
    
    @media (max-width: 1501px) {
        width: 31.7% !important;
    }
    @media (max-width: 1300px) {
        width: 31% !important;
    }
    @media (max-width: 1185px) {
        width: 48% !important;
    }
    @media (max-width: 984px) {
        width: 100% !important;
    }
    @media (max-width: 900px) {
        width: 48% !important;
    }
    @media (max-width: 834px) {
        width: 100% !important;
    }
    @media (max-width: 770px) {
        width: 31% !important;
    }
    @media (max-width: 680px) {
        width: 48% !important;
    }
    @media (max-width: 460px) {
        width: 100% !important;
    }
}