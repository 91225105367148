@import 'src/index.scss';

.notification-title-wrapper {
    @media (max-width: 1320px) {
        flex-direction: column !important;
        align-items: flex-start !important;
        
        button {
            align-self: flex-end;
            margin-top: 2rem !important;
        }
    }
}

.notification-img-wrapper {
    max-width: 15rem !important;
}

.notification-img {
    height: 8rem;
    width: 15rem;
    margin-right: 2rem;

    @media (max-width: 970px) {
        width: 11rem;

    }
    @media (max-width: 460px) {
        // width: 10rem;
        display: none;
    }
}