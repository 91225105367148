@import 'src/index.scss';

.acct-action-container {
    @media (max-width: 770px) {
        right: 3.5rem !important;
    }

    @media (max-width: 260px) {
        left: 50% !important;
        transform: translateX(-50%) !important;
        width: 90% !important;
    }
}

.acct-action-content-container {
    @media (max-width: 260px) {
        width: 100% !important;
    }
}

.acct-action-item {
    &:hover {
        background-color: #E7F6F5;

        span, p {
            color: #0A756F;
        }
    }
}