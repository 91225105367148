@import 'src/index.scss';

.details-main-container {
    @media (max-width: 1090px) {
        flex-wrap: wrap !important;
    }
}

.details-form-section {
    @media (max-width: 1090px) {
        width: 100% !important;
        margin-right: 0 !important;
    }
}

.app-details-phone {
    position: relative;
    width: 26rem;
    height: 50rem;

    @media (max-width: 1090px) {
        margin: 0 auto;
        margin-top: 2rem;
    }
}

.app-details-phone2 {
    position: relative;
    width: 26rem;
    height: 30rem;
    visibility: hidden;

    @media (max-width: 1090px) {
        display: none;
    }
}

.app-details-content-container {
    width: 100%;
    height: 100%;
    border-radius: 3.5rem;
    border: 1rem solid #000000;
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
}

.appDetails-third-design-img-wrap {
    height: 30rem !important;
    margin-top: -7rem !important;

    @media (max-width: 900px) {
        margin-top: -11rem !important;
    }
    @media (max-width: 600px) {
        margin-top: -12rem !important;
    }
}
.detail-logo-wrapper {
    @media (max-width: 1240px) {
        margin-top: 0rem !important;
    }
    @media (max-width: 1090px) {
        margin-top: 2rem !important;
    }
}

.details-upload-wrapper {
    @media (max-width: 1300px) {
        flex-wrap: wrap !important;
    }
    @media (max-width: 1090px) {
        flex-wrap: nowrap !important;
    }
    @media (max-width: 390px) {
        flex-wrap: wrap !important;
    }
}

.details-upload-block {
    @media (max-width: 1300px) {
        width: 100% !important;
    }
    @media (max-width: 1090px) {
        width: 47% !important;
    }
    @media (max-width: 390px) {
        width: 100%!important;
    }
}
 
.details-logo-flex-wrapper {
    @media (max-width: 1090px) {
        padding-right: 0 !important;
    }
}
.details-logo-upload-flex-wrapper {
    @media (max-width: 1090px) {
        width: 100% !important;
    }
}

.details-first-design-circle {
    transform: scale(0.6);
    margin-top: 2rem;
    margin-bottom: 3rem;

    @media (max-width: 900px) {
        transform: scale(0.55);
        margin-top: -1rem;
        margin-bottom: 1rem;
    }

    @media (max-width: 600px) {
        transform: scale(0.5);
       margin-bottom: 0rem;
    }
}

.logo-input {
    width: 0;
    height: 0;
    visibility: hidden;
    position: absolute
}

.urlType-selection {
    @media (max-width: 1190px) {
        width: 100% !important;
    }
    @media (max-width: 1090px) {
        width: 49% !important;
    }
}