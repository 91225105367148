@import 'src/index.scss';

.error-component-flex {
    @media (max-width: 1050px) {
        flex-direction: column-reverse !important;
    }
}

.error-content-block {
    z-index: 2 !important;
    @media (max-width: 1050px) {
        display: grid !important;
        place-items: center !important;
        p, span {
            text-align: center;
        }
    }

    @media (max-width: 500px) {
        width: auto !important;
    }
}

.fallback-illustrator {
    @media (max-width: 1050px) {
        transform: scale(0.8);
    }
    @media (max-width: 500px) {
        transform: scale(0.65);
        margin-bottom: -3rem !important;
    }

    @media (max-width: 420px) {
        transform: scale(0.55);
        margin-left: -4rem !important;
    }

    @media (max-width: 400px) {
        display: none !important;
    }
}

.fallback-circles-block {
    @media (max-width: 900px) {
        left: 0 !important;
    }
}

.error-header-logo {
    @media (max-width: 400px) {
        left: 1.5rem !important;
    }
}