@import 'src/index.scss';

.balance-control-box {
    width: 49% !important;

    @media (max-width: 500px) {
        width: 100% !important;
    }
}

.single-balance-control-box {
    width: 100% !important;

    @media (max-width: 500px) {
        width: 100% !important;
    }
}