@import 'src/index.scss';

.popup-content-container {
    overflow-y: auto !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;

    &::-webkit-scrollbar {
        display: none !important
    }
}

.popup-main-container {
    @media (max-width: 350px) {
        width: 90% !important;
    }
}