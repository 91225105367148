@import 'src/index.scss';

.report-filter-container {
    @media (max-width: 1300px) {
        top: 33rem !important;
    }

    @media (max-width: 770px) {
        top: 28rem !important;
        right: 3rem !important;
    }

    @media (max-width: 370px) {
        left: 50% !important;
        transform: translateX(-50%) !important;
        width: 90% !important;
    }
}

.report-filter-container-plus {
    // @media (max-width: 1300px) {
    //     top: 33rem !important;
    // }

    @media (max-width: 770px) {
        right: 3rem !important;
    }

    @media (max-width: 370px) {
        left: 50% !important;
        transform: translateX(-50%) !important;
        width: 90% !important;
    }
}

.report-content-container {
    @media (max-width: 370px) {
        width: 100% !important;
    }
}

.report-input-row {
    margin-bottom: 2rem !important;
  
    > div {
        width: 48% !important;
    }
}